

.introduce{
    margin-bottom: 40px;
    overflow: hidden;
    color: #666;

}
.introduce p{
    color: #666;
}
.introduce .list {
    overflow: hidden;
    margin-bottom: 40px;
}
.introduce .list .item{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #666;
    line-height: 28px;
}
.introduce .list .item .time{
    width: 200px;

}
.introduce .list .item:hover{
    color: #000;
}
.introduce .list img {
    width: 100%;
}

.introduce  .title {
    font-size: 22px;
    color: #000000;
    text-align: left;
    margin-bottom: 40px;
    overflow:hidden; 
    text-overflow:ellipsis; 
    white-space:nowrap;
    font-weight: 600;
}

.introduce .list p {
    font-size: 16px;
    color: #666;
    line-height: 28px;
}

.introduce .list .item:hover {
    color: #000;
    font-weight: 600;
}

.introduce .list .item:hover a {
    color: #000;
    font-weight: 600;
}
.introduce .story .cont .content{
    margin: 0;
    padding: 0;

}