

.news_details {
    padding-bottom: 120px;
}

.news_details .title {
    font-size: 28px;
    color: #000000;
    text-align: center;
    padding-bottom: 40px;
}
.details_img {
    margin: 0 auto;
    text-align: center;
}
.details_img img{
    width: 720px;
    margin-bottom: 40px;
}
video{
    height: 700px;
    width: 1200px;
    margin: 0 auto;
}
.news_details p {
    font-size: 18px;
    color: #666666;
    line-height: 36px;
    margin-top: 24px;
    text-indent: 2em;
}
.details .content{
    padding-top:0;
}