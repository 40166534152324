
.w .breadcrumb {
    margin: 100px auto 0px !important;
}

.city {
    text-align: center;
    padding: 80px 0;
}
.city img {
    width: 720px;
    height: 540px;
}
.city .title {
    font-size: 16px;
    color: #000;
    text-align: center;
    line-height: 24px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}
.city .look {
    margin-top: 20px;
    font-size: 10px;
    margin-top: 0px;
    font-weight: 400;
}
.city  .title  img{
    width: 7.001px;
    height: 6px;
    margin-left: 6px;

}
.city .content {
    padding: 120px 0;
}
.city:nth-child(odd) {
    background: #000;
  
}
.city:nth-child(odd) .title{
    color: #fff;
}