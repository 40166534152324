
.vlog {
    margin-top: -40px;
}
.list_vlog {
    text-align: center;
    
}

.list_vlog .item {
    padding: 80px 0;
    box-sizing: border-box;
    width: 720px;
    margin:0 auto;
    
}

.list_vlog .item p {
    margin-top: 40px;
    font-size: 16px;
    color: #000000;
    font-weight: 600;

}
.list_vlog{
    background: rgba(255, 255, 255, 0.9);
}

.list_vlog:nth-child(even) {
    background: rgba(0, 0, 0, 1);
}
.list_vlog:nth-child(even) .item p {
    color: #fff;
}

.list_vlog:nth-child(even) .p {
    color: #FFFFFF;
}

.list_vlog .item p a{
    margin-top: 40px;
    font-size: 16px;
    color: #000000;
}
.list_vlog .item .open a{
    margin-top: 20px;
    font-size: 10px;
    color: #000000;
}
.list_vlog video {
    width: 100%;
    height: 402px;
    opacity: 1;
}