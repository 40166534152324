.tabs {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    width: 720px;
    box-sizing: border-box;
    padding: 0 130px;
}

.tabs span {
    width: 80px;
    height: 30px;
    text-align: center;
    font-size: 16px;
    color: #666;
}
.tabs span:hover{
    font-size: 18px;
}
.tabs .active {
    font-size: 18px;
    color: #000;
    position: relative;
    font-weight: 600;
}
.tabs .active::after{
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    border: 5px solid;
    border-color: #fff #fff #000 #fff ;
}

.tab_content div {
    display: none;
    width: 720px;
    margin: 0px auto;
}

.tab_content .active {
    display: block;

}

.country_list {
    text-align: center;
    color: #fff;
    background: #000;
    padding: 80px 0;
}

.country_list:nth-child(even) {
    background: #fff;
}

.country_list:nth-child(even) .title {
    color: #000;
}

.country_list:last-child {
}

.country_list img {
    width: 720px;
    height: 540px;
}

.country_list .title {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    line-height: 24px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.country_list .look {
    font-weight: 400;
    font-size: 10px;
    margin-top: 0px;
}
.country_list .title  img{
    width: 7.001px;
    height: 6px;
    margin-left: 6px;
}