.download_header{
text-align: center;
}

.download_header .title{
    font-size: 36px;
    color: #333333;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 36px;
    margin: 160px auto 120px;
}
.download_header .download{
    display: flex;
    justify-content: center;

}
.download_header .download .type{
    width: 240px;
    height: 80px;
    border-radius: 40px;
    box-shadow: 0px 0px 20px #ddd;
    margin: 0 20px;
}
.download_header .download .code{
    width: 80px;
    height: 80px;
    border-radius: 40px;
    box-shadow: 0px 0px 20px #ddd;
    margin: 0 20px;
    position: relative;
}
.download_header .download .code .min{
    width: 80px;
    height: 80px;
    border-radius: 40px;
}
.download_header .download .code .max{
    position: absolute;
    right: -163px;
    top: -25px;
    animation: img-gradient 1s linear;
    display: none;
}
.download_header .download .code:hover .max{
    display: block;
}
@keyframes img-gradient {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
.download_header .download .lutu{
    position: relative;
    height: 80px;
    padding-left: 104px;
    text-align: left;
    font-size: 12px;
    color: #333333;
    line-height: 12px;
    margin:0 20px 64px;
}
.download_header .download .lutu .name{
    font-size: 20px;
    color: #333333;
    line-height: 20px;
    margin: 12px 0 16px;
}
.download_header .download .lutu .logo{
    border-radius: 12px;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 0;
    top: 0;
}
.download_banner{
    width: 100vw;
}
