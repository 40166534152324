#__banner {
    min-width: 1200px;
    width:auto;
    overflow: hidden;
    position: relative;
    max-height: 1200px;

}
#__banner .ant-carousel .slick-slide {
    text-align: center;
    height: auto;
    width: 100%;
    overflow: hidden;
    line-height: 1;
    background: #fff;
  }
  
#__banner .ant-carousel .slick-slide h3 {
    /* color: #fff; */
    
  }
#__banner2{
    min-width: 1200px;
    width: auto;
    overflow: hidden;
    position: relative;
    max-height: 1200px;
}
#__banner2 .ant-carousel .slick-slide {
    text-align: center;
    height: auto;
    width: 100%;
    overflow: hidden;
    line-height: 1;
    background: #fff;
  }
  #__banner .iconl,#__banner2 .iconl{
    height: 240px;
    padding: 100px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100px;
  }
  #__banner .iconr,#__banner2 .iconr{
    height: 240px;
    padding: 100px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 100px;
}
  .ant-carousel .slick-dots{
      height: auto;
      bottom: 40px;
  }
.ant-carousel .slick-dots li button{
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    margin: 0 10px;
}
.ant-carousel .slick-dots li.slick-active button{
    width: 8px;
}
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet{
    margin: 0 10px !important;
}
.swiper-container{
    width: 100vw;
    height: 160px;
    background: #364d79;
}
/* 顶部视频 */
.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: 40px !important;
}
.header_video {
    /* width: 100vw; */
    min-width: 1200px;
    height: 100vh;
    position: relative;
    background: #000;
}

.header_video .box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.header_video .box img {
    padding: 40px;
    position: absolute;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
}

.header_video .box .leave {
    animation: mymove 1s infinite;
    -webkit-animation: mymove 1s infinite;
    /* Safari 和 Chrome */
}

@keyframes mymove {
    0% {
        bottom: 115px;
    }
    50% {
        bottom: 85px;
    }
    100% {
        bottom: 115px;
    }
}

@-webkit-keyframes mymove {
    0% {
        bottom: 115px;
    }
    50% {
        bottom: 85px;
    }
    100% {
        bottom: 115px;
    }
}

.header_video video {
    width: 100%;
    height: 100%;
}

video:focus {
    outline: none;
}


/* .header {
    z-index: 99;
    height: 80px;
    line-height: 80px;
    width: 100%;
    background: burlywood;
} */


/* 轮播图 */

.swiper-container {
    width: 100vw;
    height: auto;
}

.swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0);
    border: 1px solid #fff;
    opacity: 1;
    box-sizing: border-box;
}

.swiper-pagination-bullet-active {
    background: #fff;
    border: 0px solid #fff;
}

.swiper-container {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    --swiper-theme-color: #fff;
    /* 设置Swiper风格 */
    --swiper-navigation-color: #fff;
    /* 单独设置按钮颜色 */
    --swiper-navigation-size: 40px;
    /* 设置按钮大小 */
}

.swiper-button-prev {
    left: 140px !important;
}

.swiper-button-next {
    right: 140px !important;
}


/* 中间内容 */

.content {
    padding-top: 120px;
}

.content .title {
    display: flex;
    justify-content: center;
}

.content .title img {
    width: 1200px;
}

.content .title p {
    text-align: center;
    font-size: 14px;
    color: #999999;
    margin-top: 12px;
}

.content .nonnative_tabs {
    display: flex;
    justify-content: space-between;
    margin: 100px auto;
}

.content .nonnative_tabs .item {
    width: 198px;
    height: 80px;
    position: relative;
}

.content .nonnative_tabs .item img {
    width: 100%;
    height: 80px;
}

.content .nonnative_tabs .item span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-size: 18px;
    color: #FFFFFF;
}

.content .swiper-content {
    min-width: 1400px;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    --swiper-theme-color: #fff;
    /* 设置Swiper风格 */
    --swiper-navigation-color: #fff;
    /* 单独设置按钮颜色 */
    --swiper-navigation-size: 40px;
    /* 设置按钮大小 */
}

.content .swiper-content img {
    width: 100%
}

.banner .swiper-container img {
    width: 100%
}

.content .video {
    margin-top: 100px;
    border: 0;
}

.content .bourn_list {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 100px 0;
}

.content .bourn_list li {
    width: 288px;
    /* height: 182px; */
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
}

.content .bourn_list li img {
    width: 100%;
    height: 182px;
}

.content .bourn_list li .name {
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 22px;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.40);
    opacity: 1;
    transform: translateY(0);
    transition: opacity 800ms ease-out, transform 600ms ease-out,
    visibility 800ms ease-out;
}

.content .bourn_list li .p1 {
    margin-top: 70px;
    font-size: 22px;
    height: 30px;
    line-height: 30px;
    color: #FFFFFF;
}

.content .bourn_list li .p2 {
    font-size: 20px;
    margin-top: 0px;
    color: #FFFFFF;
}

.content .bourn_list li .child {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.40);
    display: flex;
    flex-wrap: wrap;
    padding: 40px 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: translateY(0);
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
}
.content .bourn_list li:hover .name{
    opacity:0;
    
}
.content .bourn_list li:hover .child{
    opacity:1;
    
}
.content .bourn_list .child span {
    display: inline-block;
    width: 33%;
    text-align: center;
    height: 20px;
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 0;
    border-right: 1px solid #fff;
    color: #fff;

}
.content .bourn_list .child span a{
    color: #fff;

}

.content .bourn_list .child span:nth-child(3n+3) {
    border-right: 0px;
}
.content .bourn_list .child span:last-child{
    border-right: 0px;
}


/* 动画 */


/* 左进 */

.content .bourn_list .left_in .child {
    animation: left_in 0.5s linear;
    animation-fill-mode: forwards;
}

.content .bourn_list .left_in .name {
    animation: right_out 0.5s linear;
    animation-fill-mode: forwards;
}


/* 左出 */

.content .bourn_list .left_out .child {
    animation: left_out 0.5s linear;
    animation-fill-mode: forwards;
}

.content .bourn_list .left_out .name {
    animation: right_in 0.5s linear;
    animation-fill-mode: forwards;
}


/* 右进 */

.content .bourn_list .right_in .child {
    animation: right_in 0.5s linear;
    animation-fill-mode: forwards;
}

.content .bourn_list .right_in .name {
    animation: left_out 0.5s linear;
    animation-fill-mode: forwards;
}


/* 右出 */

.content .bourn_list .right_out .child {
    animation: right_out 0.5s linear;
    animation-fill-mode: forwards;
}

.content .bourn_list .right_out .name {
    animation: left_in 0.5s linear;
    animation-fill-mode: forwards;
}


/* 上进 */

.content .bourn_list .top_in .child {
    animation: top_in 0.5s linear;
    animation-fill-mode: forwards;
}

.content .bourn_list .top_in .name {
    animation: bottom_out 0.5s linear;
    animation-fill-mode: forwards;
}


/* 上出 */

.content .bourn_list .top_out .child {
    animation: top_out 0.5s linear;
    animation-fill-mode: forwards;
}

.content .bourn_list .top_out .name {
    animation: bottom_in 0.5s linear;
    animation-fill-mode: forwards;
}


/* 下进 */

.content .bourn_list .bottom_in .child {
    animation: bottom_in 0.5s linear;
    animation-fill-mode: forwards;
}

.content .bourn_list .bottom_in .name {
    animation: top_out 0.5s linear;
    animation-fill-mode: forwards;
}


/* 下进 */

.content .bourn_list .bottom_out .child {
    animation: bottom_out 0.5s linear;
    animation-fill-mode: forwards;
}

.content .bourn_list .bottom_out .name {
    animation: top_in 0.5s linear;
    animation-fill-mode: forwards;
}

@keyframes left_in {
    from {
        left: -430px;
        top: 0;
    }
    to {
        left: 0;
        top: 0;
    }
}

@keyframes right_in {
    from {
        left: 430px;
        top: 0;
    }
    to {
        left: 0;
        top: 0;
    }
}

@keyframes top_in {
    from {
        left: 0;
        top: -280px;
    }
    to {
        left: 0;
        top: 0;
    }
}

@keyframes bottom_in {
    from {
        left: 0;
        top: 280px;
    }
    to {
        left: 0;
        top: 0;
    }
}


/* 出 */

@keyframes left_out {
    from {
        left: 0;
        top: 0;
    }
    to {
        left: -430px;
        top: 0;
    }
}

@keyframes top_out {
    from {
        left: 0;
        top: 0;
    }
    to {
        left: 0;
        top: -280px;
    }
}

@keyframes right_out {
    from {
        left: 0;
        top: 0;
    }
    to {
        left: 430px;
        top: 0;
    }
}

@keyframes bottom_out {
    from {
        left: 0;
        top: 0;
    }
    to {
        left: 0;
        top: 280px;
    }
}