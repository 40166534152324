
.vlog {
    margin-top: -40px;
}
.list_vlog {
    text-align: center;
    
}

.list_vlog .item {
    padding: 80px 0;
    box-sizing: border-box;
    width: 720px;
    margin:0 auto;
    
}

.list_vlog .item p {
    margin-top: 40px;
    font-size: 16px;
    color: #000000;
    font-weight: 600;

}
.list_vlog{
    background: rgba(255, 255, 255, 0.9);
}

.list_vlog:nth-child(even) {
    background: rgba(0, 0, 0, 1);
}
.list_vlog:nth-child(even) .item p {
    color: #fff;
}

.list_vlog:nth-child(even) .p {
    color: #FFFFFF;
}

.list_vlog .item p a{
    margin-top: 40px;
    font-size: 16px;
    color: #000000;
}
.list_vlog .item .open a{
    margin-top: 20px;
    font-size: 10px;
    color: #000000;
}
.list_vlog video {
    width: 100%;
    height: 402px;
    opacity: 1;
}
#__banner {
    min-width: 1200px;
    width:auto;
    overflow: hidden;
    position: relative;
    max-height: 1200px;

}
#__banner .ant-carousel .slick-slide {
    text-align: center;
    height: auto;
    width: 100%;
    overflow: hidden;
    line-height: 1;
    background: #fff;
  }
  
#__banner .ant-carousel .slick-slide h3 {
    /* color: #fff; */
    
  }
#__banner2{
    min-width: 1200px;
    width: auto;
    overflow: hidden;
    position: relative;
    max-height: 1200px;
}
#__banner2 .ant-carousel .slick-slide {
    text-align: center;
    height: auto;
    width: 100%;
    overflow: hidden;
    line-height: 1;
    background: #fff;
  }
  #__banner .iconl,#__banner2 .iconl{
    height: 240px;
    padding: 100px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 100px;
  }
  #__banner .iconr,#__banner2 .iconr{
    height: 240px;
    padding: 100px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 100px;
}
  .ant-carousel .slick-dots{
      height: auto;
      bottom: 40px;
  }
.ant-carousel .slick-dots li button{
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    margin: 0 10px;
}
.ant-carousel .slick-dots li.slick-active button{
    width: 8px;
}
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet{
    margin: 0 10px !important;
}
.swiper-container{
    width: 100vw;
    height: 160px;
    background: #364d79;
}
/* 顶部视频 */
.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: 40px !important;
}
.header_video {
    /* width: 100vw; */
    min-width: 1200px;
    height: 100vh;
    position: relative;
    background: #000;
}

.header_video .box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.header_video .box img {
    padding: 40px;
    position: absolute;
    left: 50%;
    bottom: 100px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.header_video .box .leave {
    animation: mymove 1s infinite;
    -webkit-animation: mymove 1s infinite;
    /* Safari 和 Chrome */
}

@keyframes mymove {
    0% {
        bottom: 115px;
    }
    50% {
        bottom: 85px;
    }
    100% {
        bottom: 115px;
    }
}

@-webkit-keyframes mymove {
    0% {
        bottom: 115px;
    }
    50% {
        bottom: 85px;
    }
    100% {
        bottom: 115px;
    }
}

.header_video video {
    width: 100%;
    height: 100%;
}

video:focus {
    outline: none;
}


/* .header {
    z-index: 99;
    height: 80px;
    line-height: 80px;
    width: 100%;
    background: burlywood;
} */


/* 轮播图 */

.swiper-container {
    width: 100vw;
    height: auto;
}

.swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0);
    border: 1px solid #fff;
    opacity: 1;
    box-sizing: border-box;
}

.swiper-pagination-bullet-active {
    background: #fff;
    border: 0px solid #fff;
}

.swiper-container {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    --swiper-theme-color: #fff;
    /* 设置Swiper风格 */
    --swiper-navigation-color: #fff;
    /* 单独设置按钮颜色 */
    --swiper-navigation-size: 40px;
    /* 设置按钮大小 */
}

.swiper-button-prev {
    left: 140px !important;
}

.swiper-button-next {
    right: 140px !important;
}


/* 中间内容 */

.content {
    padding-top: 120px;
}

.content .title {
    display: flex;
    justify-content: center;
}

.content .title img {
    width: 1200px;
}

.content .title p {
    text-align: center;
    font-size: 14px;
    color: #999999;
    margin-top: 12px;
}

.content .nonnative_tabs {
    display: flex;
    justify-content: space-between;
    margin: 100px auto;
}

.content .nonnative_tabs .item {
    width: 198px;
    height: 80px;
    position: relative;
}

.content .nonnative_tabs .item img {
    width: 100%;
    height: 80px;
}

.content .nonnative_tabs .item span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-size: 18px;
    color: #FFFFFF;
}

.content .swiper-content {
    min-width: 1400px;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    --swiper-theme-color: #fff;
    /* 设置Swiper风格 */
    --swiper-navigation-color: #fff;
    /* 单独设置按钮颜色 */
    --swiper-navigation-size: 40px;
    /* 设置按钮大小 */
}

.content .swiper-content img {
    width: 100%
}

.banner .swiper-container img {
    width: 100%
}

.content .video {
    margin-top: 100px;
    border: 0;
}

.content .bourn_list {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 100px 0;
}

.content .bourn_list li {
    width: 288px;
    /* height: 182px; */
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
}

.content .bourn_list li img {
    width: 100%;
    height: 182px;
}

.content .bourn_list li .name {
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 22px;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.40);
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: opacity 800ms ease-out, visibility 800ms ease-out, -webkit-transform 600ms ease-out;
    transition: opacity 800ms ease-out, visibility 800ms ease-out, -webkit-transform 600ms ease-out;
    transition: opacity 800ms ease-out, transform 600ms ease-out,
    visibility 800ms ease-out;
    transition: opacity 800ms ease-out, transform 600ms ease-out,
    visibility 800ms ease-out, -webkit-transform 600ms ease-out;
}

.content .bourn_list li .p1 {
    margin-top: 70px;
    font-size: 22px;
    height: 30px;
    line-height: 30px;
    color: #FFFFFF;
}

.content .bourn_list li .p2 {
    font-size: 20px;
    margin-top: 0px;
    color: #FFFFFF;
}

.content .bourn_list li .child {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.40);
    display: flex;
    flex-wrap: wrap;
    padding: 40px 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    opacity: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: opacity 1200ms ease-out, visibility 1200ms ease-out, -webkit-transform 600ms ease-out;
    transition: opacity 1200ms ease-out, visibility 1200ms ease-out, -webkit-transform 600ms ease-out;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out, -webkit-transform 600ms ease-out;
}
.content .bourn_list li:hover .name{
    opacity:0;
    
}
.content .bourn_list li:hover .child{
    opacity:1;
    
}
.content .bourn_list .child span {
    display: inline-block;
    width: 33%;
    text-align: center;
    height: 20px;
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 0;
    border-right: 1px solid #fff;
    color: #fff;

}
.content .bourn_list .child span a{
    color: #fff;

}

.content .bourn_list .child span:nth-child(3n+3) {
    border-right: 0px;
}
.content .bourn_list .child span:last-child{
    border-right: 0px;
}


/* 动画 */


/* 左进 */

.content .bourn_list .left_in .child {
    -webkit-animation: left_in 0.5s linear;
            animation: left_in 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.content .bourn_list .left_in .name {
    -webkit-animation: right_out 0.5s linear;
            animation: right_out 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}


/* 左出 */

.content .bourn_list .left_out .child {
    -webkit-animation: left_out 0.5s linear;
            animation: left_out 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.content .bourn_list .left_out .name {
    -webkit-animation: right_in 0.5s linear;
            animation: right_in 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}


/* 右进 */

.content .bourn_list .right_in .child {
    -webkit-animation: right_in 0.5s linear;
            animation: right_in 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.content .bourn_list .right_in .name {
    -webkit-animation: left_out 0.5s linear;
            animation: left_out 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}


/* 右出 */

.content .bourn_list .right_out .child {
    -webkit-animation: right_out 0.5s linear;
            animation: right_out 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.content .bourn_list .right_out .name {
    -webkit-animation: left_in 0.5s linear;
            animation: left_in 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}


/* 上进 */

.content .bourn_list .top_in .child {
    -webkit-animation: top_in 0.5s linear;
            animation: top_in 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.content .bourn_list .top_in .name {
    -webkit-animation: bottom_out 0.5s linear;
            animation: bottom_out 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}


/* 上出 */

.content .bourn_list .top_out .child {
    -webkit-animation: top_out 0.5s linear;
            animation: top_out 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.content .bourn_list .top_out .name {
    -webkit-animation: bottom_in 0.5s linear;
            animation: bottom_in 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}


/* 下进 */

.content .bourn_list .bottom_in .child {
    -webkit-animation: bottom_in 0.5s linear;
            animation: bottom_in 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.content .bourn_list .bottom_in .name {
    -webkit-animation: top_out 0.5s linear;
            animation: top_out 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}


/* 下进 */

.content .bourn_list .bottom_out .child {
    -webkit-animation: bottom_out 0.5s linear;
            animation: bottom_out 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.content .bourn_list .bottom_out .name {
    -webkit-animation: top_in 0.5s linear;
            animation: top_in 0.5s linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes left_in {
    from {
        left: -430px;
        top: 0;
    }
    to {
        left: 0;
        top: 0;
    }
}

@keyframes left_in {
    from {
        left: -430px;
        top: 0;
    }
    to {
        left: 0;
        top: 0;
    }
}

@-webkit-keyframes right_in {
    from {
        left: 430px;
        top: 0;
    }
    to {
        left: 0;
        top: 0;
    }
}

@keyframes right_in {
    from {
        left: 430px;
        top: 0;
    }
    to {
        left: 0;
        top: 0;
    }
}

@-webkit-keyframes top_in {
    from {
        left: 0;
        top: -280px;
    }
    to {
        left: 0;
        top: 0;
    }
}

@keyframes top_in {
    from {
        left: 0;
        top: -280px;
    }
    to {
        left: 0;
        top: 0;
    }
}

@-webkit-keyframes bottom_in {
    from {
        left: 0;
        top: 280px;
    }
    to {
        left: 0;
        top: 0;
    }
}

@keyframes bottom_in {
    from {
        left: 0;
        top: 280px;
    }
    to {
        left: 0;
        top: 0;
    }
}


/* 出 */

@-webkit-keyframes left_out {
    from {
        left: 0;
        top: 0;
    }
    to {
        left: -430px;
        top: 0;
    }
}

@keyframes left_out {
    from {
        left: 0;
        top: 0;
    }
    to {
        left: -430px;
        top: 0;
    }
}

@-webkit-keyframes top_out {
    from {
        left: 0;
        top: 0;
    }
    to {
        left: 0;
        top: -280px;
    }
}

@keyframes top_out {
    from {
        left: 0;
        top: 0;
    }
    to {
        left: 0;
        top: -280px;
    }
}

@-webkit-keyframes right_out {
    from {
        left: 0;
        top: 0;
    }
    to {
        left: 430px;
        top: 0;
    }
}

@keyframes right_out {
    from {
        left: 0;
        top: 0;
    }
    to {
        left: 430px;
        top: 0;
    }
}

@-webkit-keyframes bottom_out {
    from {
        left: 0;
        top: 0;
    }
    to {
        left: 0;
        top: 280px;
    }
}

@keyframes bottom_out {
    from {
        left: 0;
        top: 0;
    }
    to {
        left: 0;
        top: 280px;
    }
}

.w .breadcrumb {
    margin: 100px auto 0px !important;
}

.city {
    text-align: center;
    padding: 80px 0;
}
.city img {
    width: 720px;
    height: 540px;
}
.city .title {
    font-size: 16px;
    color: #000;
    text-align: center;
    line-height: 24px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}
.city .look {
    margin-top: 20px;
    font-size: 10px;
    margin-top: 0px;
    font-weight: 400;
}
.city  .title  img{
    width: 7.001px;
    height: 6px;
    margin-left: 6px;

}
.city .content {
    padding: 120px 0;
}
.city:nth-child(odd) {
    background: #000;
  
}
.city:nth-child(odd) .title{
    color: #fff;
}
.tabs {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    width: 720px;
    box-sizing: border-box;
    padding: 0 130px;
}

.tabs span {
    width: 80px;
    height: 30px;
    text-align: center;
    font-size: 16px;
    color: #666;
}
.tabs span:hover{
    font-size: 18px;
}
.tabs .active {
    font-size: 18px;
    color: #000;
    position: relative;
    font-weight: 600;
}
.tabs .active::after{
    content: '';
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: -10px;
    border: 5px solid;
    border-color: #fff #fff #000 #fff ;
}

.tab_content div {
    display: none;
    width: 720px;
    margin: 0px auto;
}

.tab_content .active {
    display: block;

}

.country_list {
    text-align: center;
    color: #fff;
    background: #000;
    padding: 80px 0;
}

.country_list:nth-child(even) {
    background: #fff;
}

.country_list:nth-child(even) .title {
    color: #000;
}

.country_list:last-child {
}

.country_list img {
    width: 720px;
    height: 540px;
}

.country_list .title {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    line-height: 24px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.country_list .look {
    font-weight: 400;
    font-size: 10px;
    margin-top: 0px;
}
.country_list .title  img{
    width: 7.001px;
    height: 6px;
    margin-left: 6px;
}


.news_details {
    padding-bottom: 120px;
}

.news_details .title {
    font-size: 28px;
    color: #000000;
    text-align: center;
    padding-bottom: 40px;
}
.details_img {
    margin: 0 auto;
    text-align: center;
}
.details_img img{
    width: 720px;
    margin-bottom: 40px;
}
video{
    height: 700px;
    width: 1200px;
    margin: 0 auto;
}
.news_details p {
    font-size: 18px;
    color: #666666;
    line-height: 36px;
    margin-top: 24px;
    text-indent: 2em;
}
.details .content{
    padding-top:0;
}


.introduce{
    margin-bottom: 40px;
    overflow: hidden;
    color: #666;

}
.introduce p{
    color: #666;
}
.introduce .list {
    overflow: hidden;
    margin-bottom: 40px;
}
.introduce .list .item{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #666;
    line-height: 28px;
}
.introduce .list .item .time{
    width: 200px;

}
.introduce .list .item:hover{
    color: #000;
}
.introduce .list img {
    width: 100%;
}

.introduce  .title {
    font-size: 22px;
    color: #000000;
    text-align: left;
    margin-bottom: 40px;
    overflow:hidden; 
    text-overflow:ellipsis; 
    white-space:nowrap;
    font-weight: 600;
}

.introduce .list p {
    font-size: 16px;
    color: #666;
    line-height: 28px;
}

.introduce .list .item:hover {
    color: #000;
    font-weight: 600;
}

.introduce .list .item:hover a {
    color: #000;
    font-weight: 600;
}
.introduce .story .cont .content{
    margin: 0;
    padding: 0;

}
.download_header{
text-align: center;
}

.download_header .title{
    font-size: 36px;
    color: #333333;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 36px;
    margin: 160px auto 120px;
}
.download_header .download{
    display: flex;
    justify-content: center;

}
.download_header .download .type{
    width: 240px;
    height: 80px;
    border-radius: 40px;
    box-shadow: 0px 0px 20px #ddd;
    margin: 0 20px;
}
.download_header .download .code{
    width: 80px;
    height: 80px;
    border-radius: 40px;
    box-shadow: 0px 0px 20px #ddd;
    margin: 0 20px;
    position: relative;
}
.download_header .download .code .min{
    width: 80px;
    height: 80px;
    border-radius: 40px;
}
.download_header .download .code .max{
    position: absolute;
    right: -163px;
    top: -25px;
    -webkit-animation: img-gradient 1s linear;
            animation: img-gradient 1s linear;
    display: none;
}
.download_header .download .code:hover .max{
    display: block;
}
@-webkit-keyframes img-gradient {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
@keyframes img-gradient {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
.download_header .download .lutu{
    position: relative;
    height: 80px;
    padding-left: 104px;
    text-align: left;
    font-size: 12px;
    color: #333333;
    line-height: 12px;
    margin:0 20px 64px;
}
.download_header .download .lutu .name{
    font-size: 20px;
    color: #333333;
    line-height: 20px;
    margin: 12px 0 16px;
}
.download_header .download .lutu .logo{
    border-radius: 12px;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 0;
    top: 0;
}
.download_banner{
    width: 100vw;
}


.ant-carousel .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }
  
  .ant-carousel .slick-slide h3 {
    color: #333;
  }
.breadcrumb {
    margin: 100px auto 40px !important;
    color: #000;
    display: flex;
    align-items: center;
}
.breadcrumb span{
    color: #000;
}
.breadcrumb img {
    width: 6px;
    height: 10px;
    margin: 0 14px;
}

/* 头部 */
.header{
    position: relative;
    min-width: 1200px;
}
.fixed_top {
    position: fixed;
    top: 0;
    left: 0;
}

#header {
    height: 60px;
    background: #000000;
    width: 100%;
    /* overflow: hidden; */
    z-index: 9;
}

#header .nav {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#header .logo {
    overflow: hidden;
    height: 30px;
}

#header .logo img {
    height: 30px;
}

#header .logo h1 {
    text-indent: -1000em;
    height: 0;
}

#header .nav_item {
    text-align: center;
    height: 60px;
    line-height: 60px;
    color: #fff;
    opacity: 0.8;
    font-size: 16px;
    position: relative;
    width: 120px;
    text-align: center;
}
#header .nav_item a{
    color: #fff;
}
#header .nav_item:hover{
    font-size: 18px;
}
#header .nav_item .imgs {
    display: inline-block;
    width: 12px;
    height: 60px;
    vertical-align: top;
    margin-left: 4px;
}

#header .nav_item img {
    width: 12px;
    height: 6px;
    vertical-align: middle;
}

#header .nav_item .up {
    display: none;
}
#header .nav_item:hover .up {
    display: inline-block;
}
#header .nav_item:hover .down {
    display: none;
}

#header .nav_item .lists {
    display: none;
    position: absolute;
    right: 0;
    top: 60px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 0px 0px 6px 6px;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    padding-bottom: 20px;
    z-index: 9999;
}
#header .nav_item:hover{
    color: #fff;
}
#header .nav_item:hover .lists {
    display: block;
}
#header .item {
    padding-left: 124px;
    width: 424px;
    position: relative;
}

#header .nav_item .lists ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
}

#header .nav_item .lists li {
    width: 100px;
    margin-top: 8px;
    height: 28px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.7);
    width: 120px;
}
#header .nav_item .lists li:hover{
    font-size: 16px;
}
#header .nav_item .lists .title {
    width: 124px;
    margin-top: 8px;
    height: 28px;
    line-height: 28px;
    position: absolute;
    text-align: right;
    left: 0;
    top: 0px;
    color: rgba(255, 255, 255, 0.7);
}


.footer {
    min-width: 1200px;
    background: #000;
    padding: 100px 0;
}

.footer .logo {
    height: 60px;
    margin: 0 auto;
    /* margin-bottom: 36px; */
}
.footer .name{
    margin: 20px 0 40px;
}
.footer .center {
    text-align: center;
}

.footer .center p {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #B0B0B0;
    letter-spacing: 0;
}

.footer .center .imgs p {
    font-size: 14px;
}
.footer .center  p:hover a{
    font-size: 16px;
}
.footer .center  p a img{
    vertical-align: middle;
    margin-right: 4px;
}
.footer .center p a:hover {
    color: #fff;
}
.footer .center p a:hover {
    color: #fff;
}
.footer .center .imgs {
    margin: 0 auto;
    margin-top: 40px;
    width: 240px;
    display: flex;
    justify-content: space-between;
}

.footer .center .imgs img {
    display: block;
    width: 80px;
    height: 80px;
    background: #69c1c3;
}
.footer .center .imgs div{
    margin-top: 20px;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
li,
dt,
dd {
    /* 默认有边距，都要清除 */
    margin: 0;
    padding: 0;
    /*字体设置*/
    font-size: 14px;
    font-family: "Microsoft Yahei", sans-serif;
    color: #ccc;
    /* 去掉列表的原点 */
    list-style: none;
    /* 默认鼠标 */
    cursor: default;
}


/*行内块元素*/

input,
img {
    margin: 0;
    padding: 0;
    border: 0 none;
    outline-style: none;
    vertical-align: bottom;
}


/*行内元素*/

a,
a:active,
a:visited {
    /*下划线和颜色*/
    text-decoration: none;
    color: #ccc;
}

textarea {
    /* 边框清零 */
    border: none;
    /* 轮廓线清零 */
    outline: none;
    /* 防止文本域被随意拖拽 */
    resize: none;
}

i {
    /*文字样式*/
    font-style: normal;
}

table {
    /*边框合并*/
    border-collapse: collapse;
    border-spacing: 0;
}


/* 使用伪元素清除浮动 */

.clearfix::before,
.clearfix::after {
    content: "";
    height: 0;
    line-height: 0;
    display: block;
    visibility: none;
    clear: both;
}

.clearfix {
    *zoom: 1;
}


/* 版心*/

.w {
    width: 1200px;
    margin: 0 auto;
}

.l {
    float: left;
}

.r {
    float: right;
}


/*可选*/


/*单选框和复选框的配置，一般是分开的*/

input[type="radio"],
input[type="checkbox"] {
    -moz-appearance: none;
         appearance: none;
    -webkit-appearance: none;
    outline: none;
    display: none;
}

label {
    display: inline-block;
    cursor: pointer;
}

label input[type="radio"]+span,
label input[type="checkbox"]+span {
    width: 16px;
    height: 16px;
    display: inline-block;
}


label input[type="radio"]:checked~i,
label input[type="checkbox"]:checked~i {
    color: #38d6ff;
}


/*可选*/


/* 自定义数字框配置 */

input[type="number"] {
    width: 76px;
    height: 36px;
    background-color: rgba(5, 45, 82, 0.4);
    border: 2px solid #ccc;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    padding: 0 10px;
}

input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"]+div {
    width: 30px;
    height: 40px;
    padding-left: 2px;
    cursor: pointer;
}

input[type="number"]+div>.count_add {
    display: block;
    width: 28px;
    height: 19px;
    background-size: contain;
    margin-bottom: 2px;
}

input[type="number"]+div>.count_subtract {
    display: block;
    width: 28px;
    height: 19px;
    background-size: contain;
}
