.breadcrumb {
    margin: 100px auto 40px !important;
    color: #000;
    display: flex;
    align-items: center;
}
.breadcrumb span{
    color: #000;
}
.breadcrumb img {
    width: 6px;
    height: 10px;
    margin: 0 14px;
}

/* 头部 */
.header{
    position: relative;
    min-width: 1200px;
}
.fixed_top {
    position: fixed;
    top: 0;
    left: 0;
}

#header {
    height: 60px;
    background: #000000;
    width: 100%;
    /* overflow: hidden; */
    z-index: 9;
}

#header .nav {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#header .logo {
    overflow: hidden;
    height: 30px;
}

#header .logo img {
    height: 30px;
}

#header .logo h1 {
    text-indent: -1000em;
    height: 0;
}

#header .nav_item {
    text-align: center;
    height: 60px;
    line-height: 60px;
    color: #fff;
    opacity: 0.8;
    font-size: 16px;
    position: relative;
    width: 120px;
    text-align: center;
}
#header .nav_item a{
    color: #fff;
}
#header .nav_item:hover{
    font-size: 18px;
}
#header .nav_item .imgs {
    display: inline-block;
    width: 12px;
    height: 60px;
    vertical-align: top;
    margin-left: 4px;
}

#header .nav_item img {
    width: 12px;
    height: 6px;
    vertical-align: middle;
}

#header .nav_item .up {
    display: none;
}
#header .nav_item:hover .up {
    display: inline-block;
}
#header .nav_item:hover .down {
    display: none;
}

#header .nav_item .lists {
    display: none;
    position: absolute;
    right: 0;
    top: 60px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 0px 0px 6px 6px;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    padding-bottom: 20px;
    z-index: 9999;
}
#header .nav_item:hover{
    color: #fff;
}
#header .nav_item:hover .lists {
    display: block;
}
#header .item {
    padding-left: 124px;
    width: 424px;
    position: relative;
}

#header .nav_item .lists ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
}

#header .nav_item .lists li {
    width: 100px;
    margin-top: 8px;
    height: 28px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.7);
    width: 120px;
}
#header .nav_item .lists li:hover{
    font-size: 16px;
}
#header .nav_item .lists .title {
    width: 124px;
    margin-top: 8px;
    height: 28px;
    line-height: 28px;
    position: absolute;
    text-align: right;
    left: 0;
    top: 0px;
    color: rgba(255, 255, 255, 0.7);
}


.footer {
    min-width: 1200px;
    background: #000;
    padding: 100px 0;
}

.footer .logo {
    height: 60px;
    margin: 0 auto;
    /* margin-bottom: 36px; */
}
.footer .name{
    margin: 20px 0 40px;
}
.footer .center {
    text-align: center;
}

.footer .center p {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #B0B0B0;
    letter-spacing: 0;
}

.footer .center .imgs p {
    font-size: 14px;
}
.footer .center  p:hover a{
    font-size: 16px;
}
.footer .center  p a img{
    vertical-align: middle;
    margin-right: 4px;
}
.footer .center p a:hover {
    color: #fff;
}
.footer .center p a:hover {
    color: #fff;
}
.footer .center .imgs {
    margin: 0 auto;
    margin-top: 40px;
    width: 240px;
    display: flex;
    justify-content: space-between;
}

.footer .center .imgs img {
    display: block;
    width: 80px;
    height: 80px;
    background: #69c1c3;
}
.footer .center .imgs div{
    margin-top: 20px;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
li,
dt,
dd {
    /* 默认有边距，都要清除 */
    margin: 0;
    padding: 0;
    /*字体设置*/
    font-size: 14px;
    font-family: "Microsoft Yahei", sans-serif;
    color: #ccc;
    /* 去掉列表的原点 */
    list-style: none;
    /* 默认鼠标 */
    cursor: default;
}


/*行内块元素*/

input,
img {
    margin: 0;
    padding: 0;
    border: 0 none;
    outline-style: none;
    vertical-align: bottom;
}


/*行内元素*/

a,
a:active,
a:visited {
    /*下划线和颜色*/
    text-decoration: none;
    color: #ccc;
}

textarea {
    /* 边框清零 */
    border: none;
    /* 轮廓线清零 */
    outline: none;
    /* 防止文本域被随意拖拽 */
    resize: none;
}

i {
    /*文字样式*/
    font-style: normal;
}

table {
    /*边框合并*/
    border-collapse: collapse;
    border-spacing: 0;
}


/* 使用伪元素清除浮动 */

.clearfix::before,
.clearfix::after {
    content: "";
    height: 0;
    line-height: 0;
    display: block;
    visibility: none;
    clear: both;
}

.clearfix {
    *zoom: 1;
}


/* 版心*/

.w {
    width: 1200px;
    margin: 0 auto;
}

.l {
    float: left;
}

.r {
    float: right;
}


/*可选*/


/*单选框和复选框的配置，一般是分开的*/

input[type="radio"],
input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    display: none;
}

label {
    display: inline-block;
    cursor: pointer;
}

label input[type="radio"]+span,
label input[type="checkbox"]+span {
    width: 16px;
    height: 16px;
    display: inline-block;
}


label input[type="radio"]:checked~i,
label input[type="checkbox"]:checked~i {
    color: #38d6ff;
}


/*可选*/


/* 自定义数字框配置 */

input[type="number"] {
    width: 76px;
    height: 36px;
    background-color: rgba(5, 45, 82, 0.4);
    border: 2px solid #ccc;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    padding: 0 10px;
}

input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"]+div {
    width: 30px;
    height: 40px;
    padding-left: 2px;
    cursor: pointer;
}

input[type="number"]+div>.count_add {
    display: block;
    width: 28px;
    height: 19px;
    background-size: contain;
    margin-bottom: 2px;
}

input[type="number"]+div>.count_subtract {
    display: block;
    width: 28px;
    height: 19px;
    background-size: contain;
}